import { defineStore } from 'pinia';
import axios from 'axios';

export const useTableReviewsStore = defineStore({
    id: 'useTableReviewsStore',
    state: () => ({
      apiUrl: "https://taxupload-api.numinix.com/api/",
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("auth_taxupload")}`
      },
      customers: [],
      infoObject: {
        
      },
      total: null,
      selectedItems: [],
      infoTableObject: {}
    }),

    actions: {
        onPage(object){
          this.infoObject = object;
          console.log(object)
          return this.requestGet()
        },
        requestGet() {
          return axios.get(`${this.apiUrl}GetCustomers?`, {
            params: {
              dt_params: JSON.stringify({
                ...this.infoObject
              })
            },
            headers: {
              ...this.headers
            }
          }).then(data => {
            try{
              this.customers = data.data.payload.data;
              this.total = data.data.payload.total;
            }catch(error) {
              console.log(error, "Something is missing from Backend")
            }
            
            return data
          })
        },


        // this method make action to customer
        actionOnCustomer(actionObject) {
         return axios.post(`${this.apiUrl}AdminUpdate?`,{
            ...actionObject
          } , {
            headers: {
              ...this.headers
            }
          }).then(data => {
            if(data.data.message === "Failed to connect to api"){
              window.location.reload();
              console.log("fdkjfd")
            }else{
              this.onPage(this.infoObject)
            }
            return data
          })
        },

        deleteTax(data) {
          console.log(data)
          return axios.get(`${this.apiUrl}GetCustomers?`, {
            params: {
              dt_params: JSON.stringify({
                ...this.infoObject
              })
            },
            headers: {
              ...this.headers
            }
          }).then(data => {
            try{
              this.customers = data.data.payload.data;
              this.total = data.data.payload.total;
            }catch(error) {
              console.log(error, "Something is missing from Backend")
            }
            
            return data
          })
        }
        
    }

  });