<template>
  <div class="table-reviews container">
    <div class="body-content-this">
      <!-- H E A D E R   T A B L E -->

      <DataTable
        v-if="store.total > 0"
        class="request-table"
        :value="store.customers"
        :paginator="true"
        :rows="10"
        :lazy="true"
        :loading="loading"
        ref="dt"
        :totalRecords="store.total"
        @page="page($event)"
        @sort="page($event)"
      >
        <!-- S T A R T  B O D Y  C O N T E N T -->
        <Column
          :field="data.field"
          :header="data.header"
          :sortable="true"
          v-for="data in tableProducts"
          v-bind:key="data"
        >
          <template v-if="data.header === 'Tax'" #body="{ data }">
            <img
              :src="data.tax_document"
              alt=""
              style="height: 50px; width: 80px"
            />
          </template>

          <template v-else-if="data.header === 'Created At'" #body="{ data }">
            <span>{{ dateWithoutTimezone(data.created_at) }}</span>
          </template>

          <template v-else-if="data.header === 'Updated At'" #body="{ data }">
            <span>{{ dateWithoutTimezone(data.updated_at) }}</span>
          </template>

          <template v-else-if="data.header === 'Tax Status'" #body="{ data }">
            <div class="status" v-if="data.tax_status === 0">
              <div class="quation-status red">
                <div class="sercle"></div>
                <span> unapproved </span>
              </div>
            </div>
            <div class="status" v-else>
              <div class="quation-status blue">
                <div class="sercle"></div>
                <span> Approved </span>
              </div>
            </div>
          </template>
        </Column>
        <Column header="Actions" field="actions">
          <template #body="{ data }">
            <div class="actions">
              <Button
                v-if="data.tax_status === 1"
                type="button"
                label="Unapproved"
                icon="pi "
                @click="actionType(data, 0)"
              />
              <Button
                v-if="data.tax_status === 0"
                type="button"
                label="Approved"
                icon="pi pi-check"
                @click="actionType(data, 1)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
      <EmptyBigTable
        v-else-if="store.total !== null"
        message="No Customers found"
      ></EmptyBigTable>
    </div>
  </div>
</template>

<script>
// Start Ui Components
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import tableProducts from "@/components/tableProducts.json";
import { useTableReviewsStore } from "@/service/index";
import Button from "primevue/button";
import { ref } from "vue";
import EmptyBigTable from "@/components/EmptyBigTable.vue";

export default {
  name: "TableProductsReview",
  setup() {
    const store = useTableReviewsStore(),
      loadingAction = ref(false),
      loading = ref(false);
    return {
      tableProducts,
      store,
      loadingAction,
      loading,
    };
  },

  mounted() {
    this.store.onPage({
      first: 0,
      sortField: null,
      sortOrder: null,
      rows: 10,
    });
  },
  components: {
    Column,
    DataTable,
    Button,
    EmptyBigTable,
  },
  methods: {
    dateWithoutTimezone(originalDate) {
      const date = new Date(originalDate);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    page(object) {
      this.loading = true;
      this.store.onPage(object).then(() => {
        this.loading = false;
      });
    },

    // start action on Customer
    actionType(dataObjectCustomer, type) {
      const object = {
        tax_status: type,
        customer_id: dataObjectCustomer.customer_id,
        image_id: dataObjectCustomer.image_id,
      };
      this.loading = true;
      this.store.actionOnCustomer(object).then(() => {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
  },
};
</script>

<style lang="scss">
.table-reviews {
  margin-top: 20px;
  .body-content-this {
    border-radius: 8px;
  }
}
.p-datatable button {
  border: 1px solid #000000;
  background-color: #000;
  border-radius: 8px;
}
img {
  object-fit: contain;
}
</style>
