<template>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
    rel="stylesheet"
  />
  <upper-bar :app-name="'Tax Exemption'"></upper-bar>

  <header-plugin :component-name="title"></header-plugin>

  <!-- start content plugin -->

  <TableProductsReview></TableProductsReview>
</template>

<script>
/* eslint-disable */
import HeaderPlugin from "./components/HeaderPlugin.vue";
import upperBar from "./components/upperBar.vue";
import SiteBar from "./components/SiteBar.vue";
import TableProductsReview from "@/components/TableComponent.vue";
import { ref } from "vue";

export default {
  name: "App",
  setup() {
    // expose to template and other options API hooks
    const title = ref("Tax exemption requests");
    return { title };
  },
  components: {
    HeaderPlugin,
    upperBar,
    SiteBar,
    TableProductsReview,
  },
  methods: {
    getTitle(value) {
      this.title = value;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/global.scss";

// start
.body-content {
  background-color: #fff;
  min-height: 70vh;
  padding: 25px 18px;
  border: 1px solid #edeeef;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
</style>
