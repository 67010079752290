<template>
  <div class="container">
    <div class="title">
      <div class="row">
        <h3>{{ componentName }}</h3>
        <!-- <searchInput></searchInput> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderPlugin",
  props: {
    componentName: String,
  },
  components: {},
  data() {
    return {
      showPopupCreateQuation: false,
    };
  },
  methods: {
    showCreatQuation() {
      this.showPopupCreateQuation = true;
    },
  },
};
</script>

<style lang="scss">
// start title for plugins
.title {
  font-size: 20px;
  font-weight: 600;
  color: #202223;
  padding: 20px 0;

  button {
    align-items: center;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
  }

  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
